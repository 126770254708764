import { useNavigate } from '@remix-run/react';

import { useWorkspaces } from '~/utils/hooks/use-permissions.ts';
import { useWorkspace } from '~/utils/hooks/use-workspace.ts';

import { Variants, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { cn } from '~/utils/css/css.ts';

const containerVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const childVariants: Variants = {
  initial: {
    y: 50,
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  },
};

export const DockWorkspaceSelector = ({ onSelect }: { onSelect?: (workspace: string) => void }) => {
  const { t } = useTranslation();
  const currentWorkspace = useWorkspace();
  const availableWorkspaces = useWorkspaces();

  const options = [
    {
      value: 'app',
      title: t('workspaces.app'),
      show: availableWorkspaces.includes('app'),
    },

    {
      value: 'management',
      title: t('workspaces.management'),
      show: availableWorkspaces.includes('management'),
    },
    { value: 'admin', title: t('workspaces.admin'), show: availableWorkspaces.includes('admin') },
  ];
  const navigate = useNavigate();
  const selectWorkspace = (workspace: string) => navigate(`/${workspace}`);
  return (
    <motion.div
      variants={containerVariants}
      initial={'initial'}
      animate={'animate'}
      className={'flex gap-2 items-center bg-background'}>
      {options.map((option) => {
        if (!option.show) {
          return null;
        }
        return (
          <motion.button variants={childVariants}
            onClick={() => {
              selectWorkspace(option.value);
              onSelect && onSelect(option.value);
            }}
            key={option.value}
            type={'button'}
            className={cn('rounded-full border text-sm border-muted px-3 py-1.5 bg-background', currentWorkspace === option.value && "bg-foreground text-background")}>

            <span>{option.title}</span>
          </motion.button>
        );
      })}
    </motion.div>
  );
};
