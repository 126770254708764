import {
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '~/components/ui/command.tsx';
import { DockIcon } from '~/components/ui/dock.tsx';

import { useAvailableFeatureFlags, useFeatureFlags } from '~/utils/general/use-feature-flags.ts';
import { usePostWithIntent } from '~/utils/hooks/use-submit-with-intent.ts';

import { FlagIcon } from '@heroicons/react/24/solid';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { $path } from 'remix-routes';

export const FeatureFlagDialog = () => {
  const userFeatureFlags = useFeatureFlags();
  const availableFeatureFlags = useAvailableFeatureFlags();
  const hasFeature = (key: string) => userFeatureFlags.some((flag) => flag.key === key);
  const postWithIntent = usePostWithIntent();
  const [showDialog, setShowDialog] = useState(false);
  if (!userFeatureFlags || !availableFeatureFlags) {
    return null;
  }

  return (
    <>
      <button onClick={() => setShowDialog(!showDialog)}>
        <DockIcon tooltip={'Feature Flags'} active={false}>
          <FlagIcon className={'size-5'} />
        </DockIcon>
      </button>
      <CommandDialog open={showDialog} onOpenChange={setShowDialog}>
        <CommandInput placeholder={'Search feature flag...'} />
        <CommandList>
          <CommandEmpty>No feature flags found</CommandEmpty>
          {availableFeatureFlags.map((featureFlag) => (
            <CommandItem
              onSelect={() =>
                postWithIntent(
                  'toggleFeatureFlag',
                  { id: featureFlag.id },
                  { action: $path('/api/internal/user/feature-flags') },
                )
              }
              key={featureFlag.id}>
              {featureFlag.key}
              {hasFeature(featureFlag.key) && <Check className={'size-4 ml-2'} />}
            </CommandItem>
          ))}
        </CommandList>
      </CommandDialog>
    </>
  );
};
