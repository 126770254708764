import { isRouteErrorResponse, useNavigate, useRouteError } from '@remix-run/react';

import { FeedbackDialog } from '~/components/feedback/feedback-dialog.tsx';
import { Button } from '~/components/ui/button.tsx';
import { MaxWidth } from '~/components/ui/max-width.tsx';

import { ensureError } from '~/utils/error/error.ts';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const errorSchema = z.object({
  error: z.object({
    code: z.string().optional(),
    message: z.string(),
    status: z.number(),
    additionalData: z.record(z.string(), z.unknown()),
  }),
});

function parseError(response: unknown) {
  if (!isRouteErrorResponse(response)) {
    const error = ensureError(response);
    return {
      message: error.message,
      status: null,
    };
  }
  const parseResult = errorSchema.safeParse(response.data);
  if (!parseResult.success) {
    return {
      message: parseResult.error.message,
      status: null,
    };
  }
  return {
    message: parseResult.data.error.message,
    status: parseResult.data.error.status,
  };
}

export const ErrorComponent = () => {
  const response = useRouteError();
  console.warn(response);
  const { message, status } = parseError(response);
  const { t } = useTranslation('ui');
  const translationKeys = {
    404: 'notFound',
    500: 'internalServerError',
  } as Record<number, string>;

  const navigate = useNavigate();

  return (
    <MaxWidth className={'mt-10'}>
      <div className={'space-y-8'}>
        <div className={'space-y-6'}>
          <hgroup>
            <h3 className={'font-medium text-5xl'}>{status ?? t("error.unknownStatus")}</h3>
            <p className={'text-muted-foreground'}>
              {status ? t(`error.${translationKeys[status]}`) : t('error.unknown')}
            </p>
          </hgroup>
          <Button onClick={() => navigate(-1)}>
            <ArrowLeftIcon className={'size-4'} />
            {t('button.back')}
          </Button>
        </div>
        <div className={'rounded-xl border p-4'}>
          <p className={'font-medium'}>{t('error.help.title')}</p>
          <p className={'text-muted-foreground text-sm'}>{t('error.help.description')}</p>
          <div className={"mt-4"}>
            <FeedbackDialog display={'text'} createdAfterError={true} />
          </div>
        </div>
      </div>
    </MaxWidth>
  );
};
