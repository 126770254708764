import { NavLink } from '@remix-run/react';

import { FeatureFlagDialog } from '~/components/admin/feature-flag-dialog.tsx';
import { Badge } from '~/components/ui/badge.tsx';
import { Separator } from '~/components/ui/separator.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~/components/ui/tooltip.tsx';
import { DockWorkspaceSelector } from '~/components/workspace/dock-workspace-selector.tsx';

import { cn } from '~/utils/css/css.ts';
import { useWorkspaces } from '~/utils/hooks/use-permissions.ts';
import { useUser } from '~/utils/hooks/use-user.ts';
import { useWorkspace } from '~/utils/hooks/use-workspace.ts';

import { ComputerDesktopIcon, HomeIcon, InboxIcon, UserIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { ReactNode, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import useMeasure from 'react-use-measure';
import { $path } from 'remix-routes';
import { useTranslation } from 'react-i18next';

type DockChild = 'workspaceSelector';

export const Dock = ({ hasUnreadNotifications }: { hasUnreadNotifications?: boolean }) => {
  const [showChild, setShowChild] = useState<DockChild | undefined>();
  const toggleMenu = (menu: DockChild) => {
    if (showChild === menu) {
      setShowChild(undefined);
    } else {
      setShowChild(menu);
    }
  };
  useHotkeys('esc', () => {
    if (showChild) {
      setShowChild(undefined);
    }
  });
  const availableWorkspaces = useWorkspaces();
  const user = useUser();
  const {t} = useTranslation();

  return (
    <>
      <motion.div
        className={'fixed z-20 bottom-4 left-1/2 -translate-x-1/2 w-full flex justify-center'}>
        <motion.div
          layout
          transition={{
            layout: {
              duration: 0.3,
              stiffness: 110,
              damping: 12,
              type: 'spring',
            },
          }}
          style={{
            transformOrigin: 'bottom',
            originY: 1,
            originX: 0.5,
            borderRadius: showChild !== undefined ? 24 : 50,
          }}
          className={cn(
            'min-w-[250px] relative bg-background border shadow p-3 flex flex-col items-center gap-4',
          )}>
          <LayoutGroup>
            {showChild === 'workspaceSelector' && (
              <DockWorkspaceSelector onSelect={() => setShowChild(undefined)} />
            )}
            <motion.div
              layout={'size'}
              className={cn('flex items-center gap-6 justify-center p-3')}>
              <DockLink title={t("dock.tooltip.home")} url={'/'}>
                <HomeIcon className={'size-5'} />
              </DockLink>
              <Separator orientation={'vertical'} className={'h-6'} />
              <DockLink title={t("dock.tooltip.inbox")} url={$path('/inbox')} className={'relative'}>
                {hasUnreadNotifications ? (
                  <div className={'rounded-full bg-red-500 w-2 h-2 absolute top-0 right-0'}></div>
                ) : null}
                <InboxIcon className={'size-5'} />
              </DockLink>
              <DockLink title={t("dock.tooltip.profile")} url={$path('/me/profile')}>
                <UserIcon className={'size-5'} />
              </DockLink>
              <Separator orientation={'vertical'} className={'h-6'} />
              {availableWorkspaces.length > 1 && (
                <button
                  className={'focus:outline-none'}
                  type={'button'}
                  onClick={() => toggleMenu('workspaceSelector')}>
                  <DockIcon tooltip={t("dock.tooltip.workspace")} active={false}>
                    <ComputerDesktopIcon className={'size-5'} />
                  </DockIcon>
                </button>
              )}
              {user.administrative ? <FeatureFlagDialog /> : null}
            </motion.div>
          </LayoutGroup>
        </motion.div>
      </motion.div>
      <div
        className='fixed bottom-0 w-full h-[clamp(80px,10vh,200px)] pointer-events-none'
        style={{
          maskImage: 'linear-gradient(to top, rgb(0, 0, 0) 25%, transparent)',
          backdropFilter: 'blur(5px)',
        }}></div>
    </>
  );
};

const DockLink = ({
  children,
  title,
  url,
  className,
}: {
  children: ReactNode;
  title: string;
  url: string;
  className?: string;
}) => {
  return (
    <NavLink end={true} to={url} className={cn('focus:outline-none', className)}>
      {({ isActive }) => (
        <DockIcon tooltip={title} active={isActive}>
          {children}
        </DockIcon>
      )}
    </NavLink>
  );
};

export const DockIcon = ({
  children,
  tooltip,
  active,
}: {
  children: ReactNode;
  tooltip: string;
  active: boolean;
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <motion.div
            className={
              'hover:scale-125 transition-transform relative rounded-full hover:cursor-pointer flex items-center justify-center focus:outline-none'
            }>
            {children}
            {active && (
              <div className={'absolute -bottom-2 w-full flex justify-center rounded-full'}>
                <div className={'size-1 bg-primary/30 rounded-full'}></div>
              </div>
            )}
          </motion.div>
        </TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
