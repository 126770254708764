import React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { cn } from '~/utils/css/css.ts';
import { useIsMobile } from '~/utils/hooks/use-mobile.tsx';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

export function useDrawerDirection(){
  const isMobile = useIsMobile()
  return isMobile ? "bottom" : "right"

}


export function Drawer(props: React.ComponentProps<typeof DrawerPrimitive.Root>) {
  return <DrawerPrimitive.Root  {...props} />;
}

export const DrawerTrigger = (props: React.ComponentProps<typeof DrawerPrimitive.Trigger>) => {
  return (
    <DrawerPrimitive.Trigger
      className='relative flex h-10 flex-shrink-0 items-center gap-2 overflow-hidden rounded-full px-4 text-sm font-medium transition-all'
      {...props}
    />
  );
};

export const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentProps<typeof DrawerPrimitive.Overlay>
>((props, ref) => {
  return <DrawerPrimitive.Overlay ref={ref} className='fixed inset-0 z-20 bg-black/40' {...props} />;
});

DrawerOverlay.displayName = 'DrawerOverlay';

export const DrawerContent = (props: React.ComponentProps<typeof DrawerPrimitive.Content>) => {
  return (
    <>
      <DrawerPrimitive.Portal>
        <DrawerOverlay />
        <DrawerPrimitive.Content
          className='right-2 top-2 left-2 sm:left-auto bottom-2 fixed z-30 outline-none max-w-[640px] flex'
          {...props}
          // The gap between the edge of the screen and the drawer is 8px in this case.
          style={{ '--initial-transform': 'calc(100% + 8px)' } as React.CSSProperties}>
          <VisuallyHidden>
            <DrawerTitle>Title</DrawerTitle>
            <DrawerTitle>Description</DrawerTitle>
          </VisuallyHidden>
          <div className='h-full w-full grow p-8 flex flex-col rounded-3xl bg-background items-center overflow-y-auto scrollbar-hide'>
            {props.children}
          </div>
        </DrawerPrimitive.Content>
      </DrawerPrimitive.Portal>
    </>
  );
};

export const DrawerClose = DrawerPrimitive.Close;

export const DrawerTitle = (props: React.ComponentProps<typeof DrawerPrimitive.Title>) => {
  return (
    <DrawerPrimitive.Title className={'font-medium w-full'} {...props}>
      {props.children}
    </DrawerPrimitive.Title>
  );
};

export const DrawerDescription = (
  props: React.ComponentProps<typeof DrawerPrimitive.Description>,
) => {
  return (
    <DrawerPrimitive.Description className={'b-2'} {...props}>
      {props.children}
    </DrawerPrimitive.Description>
  );
};

export const DrawerFooter = ({
                        className,
                        ...props
                      }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("mt-auto flex flex-col gap-2 justify-self-end w-full", className)}
    {...props}
  />
)
DrawerFooter.displayName = "DrawerFooter"