import { useFetcher, useLocation } from '@remix-run/react';

import { Button } from '~/components/ui/button.tsx';
import {
  DrawerDialog,
  DrawerDialogContent,
  DrawerDialogDescription,
  DrawerDialogFooter,
  DrawerDialogHeader,
  DrawerDialogTitle,
  DrawerDialogTrigger,
} from '~/components/ui/dialog-drawer.tsx';
import { useDrawerDirection } from '~/components/ui/drawer.tsx';
import { FieldContainer } from '~/components/ui/field-container.tsx';
import { Label } from '~/components/ui/label.tsx';
import { Textarea } from '~/components/ui/textarea.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~/components/ui/tooltip.tsx';

import { FormActionResponse, SchemaType } from '~/utils/conform/conform-helper.ts';
import { cn } from '~/utils/css/css.ts';
import { isLoading } from '~/utils/general/is-loading.ts';
import { useFeature } from '~/utils/general/use-feature-flags.ts';

import { stringToBoolean } from '~/lib/schemas/schema-helper.ts';

import { useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { Check, MessageCircleQuestion } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { $path } from 'remix-routes';
import { ClientOnly } from 'remix-utils/client-only';
import { z } from 'zod';

export const createFeedbackSchema = z.object({
  feedbackCause: z.array(z.string()),
  feedbackContent: z.string().optional(),
  location: z.string(),
  createdAfterError: z.string().transform(stringToBoolean),
});

export type CreateFeedbackSchema = SchemaType<typeof createFeedbackSchema>;

export const FeedbackDialog = ({
  display,
  createdAfterError = false,
  className
}: {
  display: 'icon' | 'text';
  createdAfterError: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  const [form, fields] = useForm({
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: createFeedbackSchema });
    },
  });
  const fetcher = useFetcher<FormActionResponse>();
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.status === 'success') {
      setShowDialog(false);
    }
  }, [fetcher]);

const direction = useDrawerDirection()
  const preferDrawer = useFeature("prefer-drawers")
  return (
    <ClientOnly fallback={null}>
      {() => (
        <DrawerDialog open={showDialog} onOpenChange={setShowDialog} variant={preferDrawer ? "drawer" : "dialog"} direction={direction}>
          <DrawerDialogTrigger asChild={display === 'text'}>
            {display === 'icon' ? (
              <IconTrigger />
            ) : (
              <Button size={'lg'} variant={'outline'} className={className}>
                {t('feedback.buttons.open')}
              </Button>
            )}
          </DrawerDialogTrigger>
          <DrawerDialogContent>
            <DrawerDialogHeader>
              <DrawerDialogTitle>{t('feedback.dialog.title')}</DrawerDialogTitle>
              <DrawerDialogDescription>{t('feedback.dialog.description')}</DrawerDialogDescription>
            </DrawerDialogHeader>
            <fetcher.Form
              action={$path('/api/internal/user/feedback')}
              id={form.id}
              method={'post'}
              onSubmit={form.onSubmit}
              className={'h-full space-y-4 min-w-max'}>
              <input type='hidden' name={fields.location.name} value={location.pathname} />
              <input
                type='hidden'
                name={fields.createdAfterError.name}
                value={createdAfterError ? 'true' : 'false'}
              />
              <div className={'grid gap-2 md:grid-cols-2'}>
                <FeedbackCause title={t('feedback.cause.error')} value={'error'} />
                <FeedbackCause title={t('feedback.cause.improvement')} value={'improvement'} />
                <FeedbackCause title={t('feedback.cause.question')} value={'question'} />
                <FeedbackCause title={t('feedback.cause.idea')} value={'idea'} />
              </div>
              <FieldContainer>
                <Label>{t('feedback.fields.more')}</Label>
                <Textarea
                  name={fields.feedbackContent.name}
                  placeholder={t('feedback.placeholders.more')}
                />
              </FieldContainer>
              <DrawerDialogFooter className={'flex flex-col md:flex-row justify-end gap-2'}>
                <Button
                  size={"lg"}
                  rounded={'full'}
                  type={'button'}
                  className={"w-full"}
                  onClick={() => setShowDialog(false)}
                  variant={'secondary'}>
                  {t('actions.cancel')}
                </Button>
                <Button className={"w-full"} size={"lg"} rounded={'full'} isLoading={isLoading(fetcher)}>
                  {t('feedback.buttons.submit')}
                </Button>
              </DrawerDialogFooter>
            </fetcher.Form>
          </DrawerDialogContent>
        </DrawerDialog>
      )}
    </ClientOnly>
  );
};

const FeedbackCause = ({ title, value }: { title: string; value: string }) => {
  const [checked, setChecked] = useState(false);

  return (
    <button type={"button"}
      onClick={() => {
        setChecked(!checked);
      }}
      className={
        'hover:bg-muted/30 hover:cursor-pointer flex items-center justify-between gap-2 rounded-lg p-5 border'
      }>
      {checked && <input type='hidden' name={'feedbackCause'} value={value} />}
      <Label>{title}</Label>
      <div
        className={cn(
          'rounded size-5 border flex items-center justify-center',
          checked && 'bg-primary',
        )}>
        {checked && <Check className={'size-4 text-primary-foreground'} />}
      </div>
    </button>
  );
};

const IconTrigger = () => {
  const { t } = useTranslation();
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={'w-10 h-10 flex justify-center items-center text-muted-foreground'}>
            <MessageCircleQuestion className={'size-4'} />
          </div>
        </TooltipTrigger>
        <TooltipContent side='top' align='center' className='max-w-xs'>
          <p className='text-sm'>{t('feedback.dialog.description')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
